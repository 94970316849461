body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a {
  color: white;
}

